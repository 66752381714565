import React from 'react'
import styled from 'styled-components'
import { Row, Col } from '../Layout/Grid'
import BlogData from './BlogData'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

const BlogList = styled.div`
    background: url(${props => props.thumbnail ? props.thumbnail : 'https://picsum.photos/200/300'}) no-repeat center/cover;
    padding: 15px;
    position: relative;
    color: ${props => props.color};
    border-radius: 8px;
    overflow: hidden;
    a {
        display: block;
    }
    p {
        margin: 121px 0 0;
    }
    span {
        display: inline-block;
        &.date{
            font-size: 12px;
        }
        &.link {
            border: 2px solid white;
            font-size: 16px;
            line-height: 1.3125;
            padding: 2px 13px 2px;
            letter-spacing: 0.025em;
        }
    }
`

const Overlay = styled.div`
    background: rgba(0,0,0,.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`

const Blog = (props) => {
    const data = BlogData();
    const limit = props.posts_per_page;
    const extractedData = limit ? data.slice(0, 3) : data;
    return (
        <>
            <Row hlign={'space-between'}>
                {
                    extractedData.map(post => (
                       <Col key={post.node.wordpress_id} width={'27.4%'}>
                           <BlogList thumbnail={ post.node.featured_media.source_url }>
                               <Overlay />
                               <div style={{ position: 'relative' }}>
                                   <Link to={`/post/${post.node.slug}`}>
                                        <Row hlign={'space-between'} vcenter>
                                            <span className="link">NEWS</span>
                                            <span className="date">{post.node.date}</span>
                                        </Row>
                                        <p>{ post.node.title }</p>
                                   </Link>
                               </div>
                           </BlogList>
                       </Col>
                    ))
                }
            </Row>
        </>
    )
} 

Blog.propTypes = {
    route: PropTypes.string,
    posts_per_page: PropTypes.number
}

Blog.defaultProps = {
    route: 'home-page',
    posts_per_page: 3
}

BlogList.defaultProps = {
    color: 'white'
}


export default Blog