import { useStaticQuery, graphql } from 'gatsby'

const BlogData = () => {
    const { allWordpressPost } = useStaticQuery(graphql`
        query MyQuery {
            allWordpressPost {
                edges {
                    node {
                        wordpress_id
                        title
                        date(formatString: "MMM DD YYYY")
                        slug
                        featured_media {
                            source_url
                        }
                    }
                }
            }
        }  
    `)

    return allWordpressPost.edges;
    
}

export default BlogData;